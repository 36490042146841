.FeaturePresenterBlock {
  @apply w-full max-w-full
  flex flex-col justify-center items-center relative;


  .backgroundImageContainer {
    @apply absolute top-0 bottom-0 left-0 right-0;
    z-index: 1;

  }

  .inner {
    @apply flex flex-row justify-between items-center
    text-center max-w-screen-lg w-full gap-8 px-8 py-24;
    z-index: 1;
    &.rightToLeft {
      @screen md {
        @apply flex-row-reverse;
      }
    }

    & > * {
      flex: 1;
    }

    //min-height: calc(100vh - theme('spacing.menu-height-sm'));

    .textBlock {
      @apply flex flex-col justify-center items-center gap-8;

      background-color: #ffffffcc;
      border-radius: 16px;

      h3 {
        margin-top: -28px;
      }

      .featureContent {
        @apply text-xl;

        &:global {
          animation: fadeInRight 1s;
        }
      }

      @media (max-width: theme('screens.md')) {
        flex-grow: unset;
      }
    }

    .imageBlock {
      max-width: 300px;
    }

    .FeaturePresenterBlock__Screenshot:global {
      animation: fadeInLeft 1s;
      -webkit-filter: drop-shadow(10px 10px 10px #222);
      filter: drop-shadow(10px 10px 10px #222);
      max-width: 300px;
    }

    @media (max-width: theme('screens.md')) {
      @apply flex-col justify-center;
      margin-top: 0 !important;
    }
  }

  &.darkStyle {
    @apply bg-color-primary text-color-light;

    .textBlock {
      background-color: rgba(var(--rms-color-primary-rgb), 0.7);
      border-radius: 16px;
    }
  }
}
.RMSVerticalInfoElement {
  max-width: 300px;
  background-color: #ffffffcc;

  .RMSVerticalInfoElement__Inner {
    @apply flex flex-col justify-center items-center gap-4;

    .headerContainer {
      @apply gap-4;
      display: grid;
      grid-template-areas: "imageContainer" "title" "subtitle";

      &.imgTitleSub {
        grid-template-areas: "imageContainer" "title" "subtitle";
      }

      &.titleImgSub {
        grid-template-areas: "title" "imageContainer" "subtitle";
      }

      &.titleSubImg {
        grid-template-areas: "title" "subtitle" "imageContainer";
      }

      .imageContainer {
        grid-area: imageContainer;
        .elementIcon {
          @apply text-8xl;
        }
      }

      .title {
        @apply text-center;

        grid-area: title;
      }

      .subtitle {
        @apply text-center;

        grid-area: subtitle;
      }
    }

    &.colorStyleLight {
      @apply text-color-dark;
    }

    &.colorStyleDark {
      @apply text-color-light;
    }

    &.colorStyleLightHighlight {
      @apply text-color-dark;

      .title {
        @apply text-color-primary;
      }

      .subtitle {

        color: var(--rms-color-primary-shade);
      }

      .elementIcon {
        color: var(--rms-color-primary-shade);
      }
    }

    &.colorStyleDarkHighlight {
      @apply text-color-light;
    }
  }
}
.RMSDialogCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: var(--rms-color-light);
  border-radius: 16px;
  overflow: hidden;
  max-width: var(--rms-dialog-screens-small, 500px);
  border: 4px solid var(--rms-color-primary);
  background-color: var(--rms-color-light);

  &:global {
    animation: fadeInUp 0.5s;
  }

  &.close {
    &:global {
      animation: bounceOutDown 1s;
    }
  }

  .header, .footer {
    width: 100%;
    padding: 16px;
    background-color: var(--rms-color-primary);
    color: var(--rms-color-light);

    .title {
      text-align: center;
    }
  }

  .content {
    padding: 16px;
  }

  .footer {

  }

  @media (max-width: 500px) {
    width: 100%;
    border-radius: 16px 16px 0 0;
    border: none !important;
  }
}
.rootPageLoading {
  width: 100vw;
  height: 100vh;
  background: var(--rms-color-primary);

  .loadingMidContainer {
    @apply fixed top-0 bottom-0 left-0 right-0 m-auto flex flex-col items-center justify-center gap-4;

    img {
      @apply bg-white bg-opacity-25 p-4 rounded-full;
      width: 200px;
    }

    h2 {
      @apply text-color-light text-center;
    }
  }
}

.errorContainer {
  @apply p-32 flex flex-col items-center justify-center max-w-screen-md gap-8 text-color-light text-center;

  button {
    @apply text-6xl;
  }

  .errorSubtitle {
    @apply text-center;
  }

  .errorContent {
    margin-top: -16px;
  }
}
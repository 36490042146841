@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'styles/variables.css';
@import 'animate.css';
@import "styles/rms-styles/rms-styles";

@layer base {

  ul {
    @apply text-left list-disc pl-8;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply text-left;
  }

  h1 {
    @apply text-5xl font-bold;
    font-family: Handlee, serif;
    animation: textPulsate 2s linear infinite alternate;;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  h4 {
    @apply text-xl;
  }
  h5 {
    @apply text-lg font-bold;
  }

  button {
    @apply p-2 rounded-lg text-lg;

    @media (max-width: theme('screens.md')) {
      @apply p-4;
    }

    &.primary {
        @apply bg-color-primary text-color-light;
    }

    &.secondary {
        @apply bg-transparent text-color-primary border-color-primary border-2;

        &.light {
            @apply text-color-light border-color-light;
        }
    }
  }

  p {
    @apply text-left break-words text-xl;
  }
}

body {
  margin: 0;
  font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

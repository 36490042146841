/* variables.css */

:root {
    --rms-font-family: 'Ubuntu';

    --rms-color-primary: #138e37;
    --rms-color-primary-rgb: 19, 142, 55;
    --rms-color-primary-contrast: #ffffff;
    --rms-color-primary-contrast-rgb: 255,255,255;
    --rms-color-primary-shade: #26995a;
    --rms-color-primary-tint: #40b675;

    --rms-color-secondary: #DBB04A;
    --rms-color-secondary-rgb: 219,176,74;
    --rms-color-secondary-contrast: #000000;
    --rms-color-secondary-contrast-rgb: 0,0,0;
    --rms-color-secondary-shade: #c19b41;
    --rms-color-secondary-tint: #dfb85c;

    --rms-color-tertiary: #97B3D0;
    --rms-color-tertiary-rgb: 151,179,208;
    --rms-color-tertiary-contrast: #000000;
    --rms-color-tertiary-contrast-rgb: 0,0,0;
    --rms-color-tertiary-shade: #859eb7;
    --rms-color-tertiary-tint: #a1bbd5;

    --rms-color-success: #37cd7b;
    --rms-color-success-rgb: 55,205,123;
    --rms-color-success-contrast: #000000;
    --rms-color-success-contrast-rgb: 0,0,0;
    --rms-color-success-shade: #30b46c;
    --rms-color-success-tint: #4bd288;

    --rms-color-warning: #e0bc65;
    --rms-color-warning-rgb: 224,188,101;
    --rms-color-warning-contrast: #000000;
    --rms-color-warning-contrast-rgb: 0,0,0;
    --rms-color-warning-shade: #c5a559;
    --rms-color-warning-tint: #e3c374;

    --rms-color-danger: #E94B3C;
    --rms-color-danger-rgb: 233,75,60;
    --rms-color-danger-contrast: #ffffff;
    --rms-color-danger-contrast-rgb: 255,255,255;
    --rms-color-danger-shade: #cd4235;
    --rms-color-danger-tint: #eb5d50;

    --rms-color-dark: #211e28;
    --rms-color-dark-rgb: 33,30,40;
    --rms-color-dark-contrast: #ffffff;
    --rms-color-dark-contrast-rgb: 255,255,255;
    --rms-color-dark-shade: #1d1a23;
    --rms-color-dark-tint: #37353e;

    --rms-color-medium: #DFDCE5;
    --rms-color-medium-rgb: 223,220,229;
    --rms-color-medium-contrast: #000000;
    --rms-color-medium-contrast-rgb: 0,0,0;
    --rms-color-medium-shade: #c4c2ca;
    --rms-color-medium-tint: #e2e0e8;

    --rms-color-light: #f3f2f5;
    --rms-color-light-rgb: 243,242,245;
    --rms-color-light-contrast: #000000;
    --rms-color-light-contrast-rgb: 0,0,0;
    --rms-color-light-shade: #d6d5d8;
    --rms-color-light-tint: #f4f3f6;

/** inventory **/
--app-color-items-new: var(--rms-color-success);
--app-color-items-open: var(--rms-color-warning);
--app-color-items-expired: var(--rms-color-danger);

/** spaces **/
--app-space-small: 4px;
--app-space-default: 8px;
--app-space-big: 16px;
--app-space-super-big: 32px;

--app-margin-default: var(--app-space-default);

/** font **/
--app-font-size-normal: 16px;
--app-font-size-small: 14px;
--app-font-size-big: 20px;
--app-font-size-super-big: 24px;

--app-font-size-h1: var(--app-font-size-super-big);
--app-font-size-h2: var(--app-font-size-big);
--app-font-size-h3: var(--app-font-size-normal);
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/montserrat/Montserrat-Medium.ttf');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/ubuntu/Ubuntu-Regular.ttf');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: normal;
    src: url('../assets/fonts/ubuntu/Ubuntu-Italic.ttf');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: bold;
    src: url('../assets/fonts/ubuntu/Ubuntu-Bold.ttf');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: bold;
    src: url('../assets/fonts/ubuntu/Ubuntu-BoldItalic.ttf');
}

@font-face {
    font-family: 'Handlee';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/handlee/Handlee-Regular.ttf');
}
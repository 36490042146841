.MainFooter {
  @apply bg-color-primary text-color-light flex flex-col items-center justify-center;

  .MainFooter__Inner {
    @apply flex flex-col items-center justify-center max-w-screen-xl;

    .MenuContainer {
      @apply flex flex-wrap justify-center items-center gap-4 p-8;
    }
  }
}
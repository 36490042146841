.RMSDialog {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  &.close {
    .RMSDialog__Background:global {
      animation: fadeOutUp 1s;
    }
  }

  .RMSDialog__Background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000033;

    &:global {
      animation: fadeInDown 0.5s;
    }
  }

  .RMSDialog__Content {
    z-index: 1;
  }

  @media (max-width: 500px) {
    justify-content: end;
  }
}
.HomeHeaderBlock {
  @apply w-full max-w-full
  flex flex-col justify-center items-center relative;

  .backgroundImage {
    @apply absolute top-0 bottom-0 left-0 right-0;
    z-index: -1;

    img:global {
      animation: zoomIn 1s;
    }
  }

  .showMoreTextContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--rms-color-light);
    padding: 16px;

    &:global {
      animation: fadeInUp 1s;
    }

    .box {
      @apply flex flex-col justify-center items-center text-3xl;

      text-shadow: 0 0 10px theme('colors.color-dark');
      background: var(--rms-color-primary);
      padding: 8px 16px;
      gap: 8px;
      flex-direction: row;
      border-radius: 16px;

      &:global {
        animation: pulse 1.5s infinite;
      }

      @media (max-width: theme('screens.md')) {
        box-shadow: 0 0px 5px #fff;
      }
    }
  }

  .inner {
    @apply flex flex-row-reverse justify-between items-center
    text-center max-w-screen-lg w-full gap-8 p-5 pb-16;

    & > * {
      flex: 1;
    }

    min-height: calc(100vh - theme('spacing.menu-height-sm'));

    .innerRightSide {
      @apply flex flex-col justify-center items-center gap-8;

      .headerContent {
        @apply text-color-light text-xl;

        &:global {
          animation: fadeInRight 1s;
        }

        p {
          @apply text-center;
        }
      }

      h1, h2 {
        @apply text-color-light text-center;
        flex-grow: 1;
      }

      h1 {
        &:global {
          animation: fadeInRight 1s, textPulsate 2s linear infinite alternate;
        }
      }

      h2 {
        @apply font-light;

        margin-top: -24px;

        &:global {
          animation: fadeInUp 1s;
        }
      }

      @media (max-width: theme('screens.md')) {
        @apply gap-14 mt-8;
        flex-grow: unset;
      }
    }

    .HomeHeaderBlock__Screenshot:global {
      width: 300px !important;
      animation: fadeInLeft 1s;
      -webkit-filter: drop-shadow(10px 10px 10px #222);
      filter: drop-shadow(10px 10px 10px #222);
    }

    @media (max-width: theme('screens.md')) {
      @apply flex-col justify-center;
    }
  }
}
.TopHeader {
  @apply
  flex flex-col gap-y-8 justify-center items-center
  bg-[var(--rms-color-primary)]
  text-color-light
  h-menu-height-sm;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .TopHeader__Line {
    @apply flex flex-row w-full justify-between px-4 max-w-screen-xl items-center;

    animation: fadeInDown 0.5s;

    .TopHeader__MobileButton {
      @apply text-4xl text-color-light;
      display: none;
    }

    @media (max-width: theme("screens.md")) {
      .TopHeader__MobileButton {
        display: flex;
      }
    }
  }

  .TopHeader__Line__Logo {
    @apply
    flex flex-row justify-center items-center
    w-menu-icon-sm h-menu-icon-sm bg-color-light;

    border-radius: 8px;
  }

  .TopHeader__Line__Menu {
    @apply flex flex-row gap-x-4 justify-center items-center;
  }

  .MobileMenu {
    display: none;
  }

  @media (max-width: theme("screens.md")) {
    .TopHeader__Line__Menu {
      display: none;
    }
    .MobileMenu {
      &.show {
        display: flex;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: rgba(var(--rms-color-dark-rgb), 0.5);
        flex-direction: row-reverse;

        .MobileMenuContainer {
          @apply text-2xl;

          height: 100%;
          background: var(--rms-color-primary);
          padding: 64px;
          border-radius: 8px 0 0 8px;
          box-shadow: -1px 0 8px var(--rms-color-light);
          position: relative;
          animation: fadeInRight 0.5s;

          .Seperator {
            @apply h-0.5 w-full bg-color-light mt-4 mb-8;

            border-radius: 8px;
          }

          .closeIcon {
            @apply absolute top-4 right-4 text-4xl font-bold;
          }

          .MenuItemContainer {
            @apply flex flex-col gap-2;
          }
        }
      }
    }
  }

  .MenuItem {

  }
}
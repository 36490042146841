.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.widthSm {
   @apply max-w-screen-sm;
}

.widthMd {
   @apply max-w-screen-md;
}

.widthLg {
   @apply max-w-screen-lg;
}

.widthXl {
   @apply max-w-screen-xl;
}

.alignLeft {
  @apply justify-start items-start;
}

.alignCenter {
  @apply justify-center items-center;
}

.alignRight {
  @apply justify-end items-end;
}

@media (max-width: theme("screens.md")) {
  .widthSm, .widthMd, .widthLg, .widthXl {
    max-width: 100% !important;
    padding: 16px;
  }
}